import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public user: Observable<firebase.User>;
  private authProvider: any;
  public isProgress: boolean;

  constructor(private afAuth: AngularFireAuth, private router: Router) {
  }

  login() {
    this.authProvider = new firebase.auth.GoogleAuthProvider();
    this.afAuth.auth.signInWithRedirect(this.authProvider);
    window.sessionStorage.ngRedirect = true;
  }

  logout() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['login']);
      this.isProgress = false;
    });
  }

  ngOnInit() {
    this.isProgress = window.sessionStorage.ngRedirect;
    this.user = this.afAuth.authState;
    this.afAuth.auth.getRedirectResult().then(result => {
      if (result.user) {
        this.router.navigate(['for-you']);
        window.sessionStorage.removeItem('ngRedirect');
      }
    });
  }
}
