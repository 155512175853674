export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAwKBhBfOCWQE_rCJQtRJaydZy1m8vqiDk',
    authDomain: 'markingcloud-for-you.firebaseapp.com',
    databaseURL: 'https://markingcloud-for-you.firebaseio.com',
    projectId: 'markingcloud-for-you',
    storageBucket: 'markingcloud-for-you.appspot.com',
    messagingSenderId: '371170566940',
    appId: '1:371170566940:web:751ab4ce2374b3159410d9'
  }
};
