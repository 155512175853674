import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import * as firebase from 'firebase';

export interface Message {
  from: string;
  message: string;
  image: string;
}

@Component({
  selector: 'app-for-you',
  templateUrl: './for-you.component.html',
  styleUrls: ['./for-you.component.css']
})
export class ForYouComponent implements OnInit {
  messages: Array<Message>;
  userName: string;

  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    const user = firebase.auth().currentUser;
    this.afs.collection('for-you').ref.where('email', '==', user.email)
      .get()
      .then(snapshot => {
        if (snapshot.empty) {
          console.log('No matching documents.');
          return;
        }
        snapshot.forEach(doc => {
          console.log(doc.id, '=>', doc.data());
          this.userName = doc.id;
          this.messages = doc.data()['2019'];
        });
      })
      .catch(err => {
        console.log('Error getting documents', err);
      });
  }

}
