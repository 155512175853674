import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'marking-cloud';

  constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.document.documentElement.lang = 'ja';
  }
}
